const userAgent = navigator.userAgent || navigator.vendor || window.opera;
const style = document.createElement("style");
style.type = "text/css";

if (/android/i.test(userAgent)) {
  style.innerHTML = ".show-on-ios { display: none; }";
  style.innerHTML += " .hide-on-mobile-os { display: none; }";
} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  style.innerHTML = ".show-on-android { display: none; }";
  style.innerHTML += " .hide-on-mobile-os { display: none; }";
} else {
  style.innerHTML = ".show-on-ios { display: none; }";
  style.innerHTML += " .show-on-android { display: none; }";
}

document.getElementsByTagName("head")[0].appendChild(style);
